<template>
    <Carousel v-bind:settings="carouselSettings" :breakpoints="breakpointsConfig" class="m-0">
        <Slide v-for="(service, index) in services" :key="index">
            <div class="flex carousel__item">
                <ServiceCard :lable="service.lable" :imageUrl="service.imageUrl" :infoList="service.infoList" />
            </div>
        </Slide>

        <template #addons>
            <Navigation class="hidden sm:block" />
        </template>
    </Carousel>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { ref } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import breakpointsConfig from '@/components/carousels/breakpointsConfig.js';
import ServiceCard from '@/components/cards/ServiceCard.vue'

const carouselSettings = ref({
    itemsToShow: 1,
    snapAlign: 'center',
    transition: 500,
    wrapAround: true,
})

const services = ref([
    {
        lable: 'Клининг',
        imageUrl: 'images/services/1.png',
        infoList: [
            'Предоставление штата опытных клинеров',
            'Используем проф. оборудование и качественную химию',
        ],
    },
    {
        lable: 'Дворники',
        imageUrl: 'images/services/2.png',
        infoList: [
            'Приведение прилегающих территорий в чистоту и порядок ',
        ],
    },
    {
        lable: 'Комплектовщики',
        imageUrl: 'images/services/3.png',
        infoList: [
            'Комплектация всех видов грузов и товаров',
            'Сортировка и разделение товара',
        ],
    },
    {
        lable: 'Штукатурщики',
        imageUrl: 'images/services/4.png',
        infoList: [
            'Занимаемся различными видами облицовочных работ',
            'Выполнение работ мастерами с большим опытом',
        ],
    },
    {
        lable: 'Грузчики',
        imageUrl: 'images/services/5.png',
        infoList: [
            'Работа с разными грузами',
            'Возможнсть заказа большой бригады',
            'Выполнение в любые сроки',
        ],
    },
    {
        lable: 'Упаковщики',
        imageUrl: 'images/services/6.png',
        infoList: [
            'Работа с грузами разных форматов',
            'Гарантия сохранности товара',
            'Выполнение в удобные для вас сроки',
        ],
    },
    {
        lable: 'Сварщики',
        imageUrl: 'images/services/7.png',
        infoList: [
            'Выполнение сварочных работ любой сложности',
            'Предоставление дипломированных специалистов',
            'Гарантия качества работы',
        ],
    },
    {
        lable: 'Овощеводы',
        imageUrl: 'images/services/8.png',
        infoList: [
            'Предоставление квалифицированных специалистов, а также оборудования и инструментов',
            'Контроль качества',
        ],
    },
    {
        lable: 'Каменщик',
        imageUrl: 'images/services/9.png',
        infoList: [
            'Предоставление сертифицированных мастеров',
            'Гарантия качества и долговечности',
        ],
    },
    {
        lable: 'Разнорабочие',
        imageUrl: 'images/services/10.png',
        infoList: [
            'Возможность предоставления большой бригады рабочих',
        ],
    },
    {
        lable: 'Карщики',
        imageUrl: 'images/services/11.png',
        infoList: [
            'Предоставление опытных сотрудников',
            'Гарантия сохранности транспортируемых грузов',
        ],
    },
])

</script>

<style lang="scss">
.carousel__item {
    block-size: fit-content;
}

.carousel__slide {
    padding: 5px;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 0.5;
}

.carousel__slide--prev {
    opacity: 1;
}

.carousel__slide--next {
    opacity: 1;
}

.carousel__slide--active {
    opacity: 1;
}
</style>