<template>
    <header>
        <div class="relative">
            <div class="absolute h-full gradient-border-left"></div>
            <div class="static bg-cover bg-center bg-no-repeat w-full"
                :style="`height: 75vh; background-image: url('${headerImage}')`">
                <div class="absolute z-1 w-full">
                    <div class="static w-full gradient-border-top"></div>
                    <AppbarComponent :textHexColor="appbarTextColor" :backgroundHexColor="appbarBackgroundColor" />
                </div>
                <div class="absolute z-0 w-full h-full grid">
                    <div class="col-10 sm:col-5 col-offset-1 flex align-items-center">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import AppbarComponent from '@/components/AppbarComponent.vue'

const appbarTextColor = ref('rgba(255, 255, 255, 1)')
const appbarBackgroundColor = ref('rgba(0, 0, 0, 0)')

const props = defineProps({
    headerImage: {
        type: String,
        default: () => "images/homePage/header_image.png"
    }
})

</script>

<style lang="scss">
.gradient-border-top {
    height: 10px;
    background-image: linear-gradient(to right, rgba(13, 52, 255, 1), rgba(218, 51, 255, 1));
}

.gradient-border-left {
    width: 10px;
    background-image: linear-gradient(to bottom, rgba(13, 52, 255, 1), rgba(218, 51, 255, 1));
}
</style>