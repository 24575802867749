const breakpointsConfig = {
    576: {
        itemsToShow: 1.7,
        snapAlign: 'center',
    },
    768: {
        itemsToShow: 2.5,
        snapAlign: 'center',
    },
    992: {
        itemsToShow: 3,
        snapAlign: 'center',
    },
    1200: {
        itemsToShow: 4.5,
        snapAlign: 'center',
    },
}

export default breakpointsConfig