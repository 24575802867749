<template>
  <HeaderComponent>
    <template #content>
      <div class="text-lg sm:text-3xl header-content-text" style="max-width: 550px;">
        Компания биллион специализируется на Предоставлении сотрудников для различных видов
        подрядных
        работ
      </div>
    </template>
  </HeaderComponent>

  <main>
    <section class="py-4">
      <div class="grid py-3">
        <div class="sm:col-10 col-offset-1 flex flex-row">
          <SectionTitle title="Наши услуги" uppercase />
        </div>
      </div>
      <ServiceCarousel />
      <div class="grid my-3">
        <div class="col-10 col-offset-1 flex flex-row justify-content-end">
          <GradientButton class="h-2rem border-round-2xl px-4" lable="Не нашли подходящую услугу?"
            @click="router.push({ name: 'Home', hash: '#service-request' })" />
        </div>
      </div>
    </section>
    <section class="py-4">
      <ServiceRequestForm id="service-request" />
    </section>
    <section class="py-4">
      <div class="grid hidden sm:block">
        <div class="col-10 col-offset-1 flex flex-row">
          <SectionTitle title="Актуальные вакансии" uppercase />
        </div>
      </div>
      <SimpleCardList class="hidden sm:block" :vacancies="vacancies" />
    </section>
    <section class="py-4">
      <div class="grid py-3">
        <div class="col-10 col-offset-1 flex-column flex text-center sm:flex-row align-items-center">
          <div style="font-size: 22px; line-height: 42px; font-weight: 400;">
            Ведётся набор сотрудников различных специальностей
          </div>
          <AngleRight class="hidden sm:block" />
          <GradientButton class="h-3rem border-round-xl px-4" lable="Перейти ко всем вакансиям"
            @click="router.push({ name: 'Vacancies' })" />
        </div>
      </div>
    </section>
    <section class="py-4">
      <div class="grid">
        <div class="col-10 col-offset-1 flex flex-row">
          <SectionTitle title="Новости компании" uppercase />
        </div>
      </div>
      <div class="grid">
        <div class="col-10 col-offset-1">
          <GradientDeviderLine />
        </div>
      </div>
      <ArticleCarousel :articles="articles" />
      <div class="grid">
        <div class="col-6 col-offset-3">
          <GradientDeviderLine :gradientDirection="'left'" />
        </div>
      </div>
    </section>
    <section class="py-4">
      <NewPartner />
    </section>
    <section class="py-4">
      <ListOfPartners />
    </section>
  </main>
  <FooterComponent />
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import AngleRight from '@/components/icons/AngleRight.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import GradientButton from '@/components/GradientButton.vue'
import ServiceCarousel from '@/components/carousels/ServiceCarousel.vue'
import SimpleCardList from '@/components/SimpleCardList.vue'
import ArticleCarousel from '@/components/carousels/ArticleCarousel.vue'
import GradientDeviderLine from '@/components/deviders/GradientDeviderLine.vue'
import ListOfPartners from '@/components/ListOfPartners.vue'
import NewPartner from '@/components/NewPartner.vue'
import ServiceRequestForm from '@/components/ServiceRequestForm.vue'

const router = useRouter()

const vacancies = ref([
  {
    title: 'Грузчик',
    description: 'Погрузочные работы',
  },
  {
    title: 'Овощевод',
    description: 'Посадка и выращивание овощных культур',
  },
  {
    title: 'Упаковщик',
    description: 'Обеспечение целостности товара',
  },
  {
    title: 'Карщик',
    description: 'Водитель автопогрузчика',
  },
])

const articles = ref([
  {
    imageUrl: 'images/articles/1.svg',
    summary: 'Участие в создании нового большого проекта по постройке загородных домов.',
    date: '22.03.2023',
  },
  {
    imageUrl: 'images/articles/2.svg',
    summary: 'Встреча с партнерами по воспросу расширения штаба работников.',
    date: '19.02.2023',
  },
  {
    imageUrl: 'images/articles/3.svg',
    summary: 'Встреча с партнерами по воспросу расширения штаба работников.',
    date: '19.02.2023',
  },
  {
    imageUrl: 'images/articles/4.svg',
    summary: 'Начало сотрудничества с новыми крупными компаниями.',
    date: '27.04.2023',
  },
])
</script>

<style lang="scss">
.header-content-text {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}
</style>
