<template>
    <div style="background-color: white;">
        <div class="grid">
            <div class="col-10 sm:col-5 col-offset-1 py-5">
                <div class="flex flex-column">
                    <div class="mb-8">
                        <div>
                            <h2 class="text-lg sm:text-2xl title">Предлагаем сотрудничество компаниям</h2>
                        </div>
                        <div class="text-lg sm:text-2xl contact-with-us">
                            Свяжитесь с нами <br>
                            <a class="no-underline" style="color: black;" :href="`tel:${templateConfig.mobileShortPhone}`">
                                {{ templateConfig.mobileShortPhoneView }}
                            </a>
                        </div>
                        <div class="text-lg sm:text-2xl or mb-2">
                            или
                        </div>
                        <div>
                            <GradientButton class="h-2rem border-round-xl px-4" :lable="'Запросить звонок'"
                                @click="router.push({ name: 'Home', hash: '#service-request' })" />
                        </div>
                    </div>
                    <div class="text-base sm:text-xl description">
                        Организация сотрудничества для достижения совместных <br> целей и развития
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import templateConfig from '@/store/templateConfig'
import GradientButton from '@/components/GradientButton.vue'

const router = useRouter()
</script>

<style lang="scss" scoped>
.title {
    font-weight: 400;
    text-transform: uppercase;
}

.contact-with-us {
    font-weight: 400;
}

.or {
    font-weight: 400;
    color: rgb(110, 120, 253);
}

.description {
    font-weight: 400;
    color: rgb(147, 147, 147);
}
</style>