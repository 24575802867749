import apiClient from "@/api/client.js"

export default {
    sendServiceForm: (data) => {
        return apiClient.post('/service_request_form', data)
    },

    sendVacanciesForm: (data) => {
        return apiClient.post('/vacancy_request_form', data)
    }
}