<template>
    <div id="appbar" class="flex flex-row align-items-center justify-content-between px-4 sm:px-8"
        :style="`color: ${textHexColor}; background-color: ${backgroundHexColor};`">
        <div>
            <h1 class="m-0 text-4xl">Биллион</h1>
        </div>
        <div class="hidden md:flex flex-row">
            <div class="flex flex-row mr-5">
                <div v-for="(link, index) in links" :key="index"
                    @click="router.push({ name: link.routeName, hash: link.hash })" class="ml-5">
                    <a class="no-underline hover:no-underline cursor-pointer">{{ link.lable }}</a>
                </div>
            </div>
            <div class="flex flex-row align-items-center">
                <div v-for="(action, index) in actionButtons" :key="index" @click="action.action()"
                    class="ml-4 cursor-pointer">
                    <span :class="action.icon ? `pi pi-${action.icon}` : ''"></span>
                    <span>{{ action.lable }}</span>
                </div>
            </div>
        </div>
        <div class="flex md:hidden flex-row">
            <div @click="sidebarIsShowing = true" class="ml-4 cursor-pointer">
                <span class="pi pi-bars"></span>
            </div>
            <div @click="" class="ml-4 cursor-pointer">
                <span class="pi pi-user"></span>
            </div>
        </div>
    </div>
    <Sidebar v-model:visible="sidebarIsShowing" :position="'right'">
        <div class="flex flex-column text-right">
            <div v-for="(link, index) in links" :key="index" @click="router.push({ name: link.routeName, hash: link.hash })"
                :class="index < links.length - 1 ? 'mb-4' : ''">
                <a class="no-underline text-xl hover:no-underline cursor-pointer">{{ link.lable }}</a>
            </div>
            <DeviderLine />
        </div>
    </Sidebar>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useRouter } from 'vue-router'
import templateConfig from '@/store/templateConfig'
import DeviderLine from '@/components/deviders/DeviderLine.vue'
import Sidebar from 'primevue/sidebar'

const router = useRouter()

const props = defineProps({
    textHexColor: {
        type: String,
        default: () => '#000000',
    },
    backgroundHexColor: {
        type: String,
        default: () => '#ffffff',
    },
})

const sidebarIsShowing = ref(false)

const links = ref([
    {
        lable: 'Главная',
        routeName: 'Home',
        hash: '',
    },
    {
        lable: 'Вакансии',
        routeName: 'Vacancies',
        hash: '',
    },
    {
        lable: 'О нас',
        routeName: 'About',
        hash: '',
    },
])

const actionButtons = ref([
    // {
    //     icon: 'bars',
    //     lable: '',
    //     action: () => sidebarIsShowing = true,
    // },
    // {
    //     icon: 'user',
    //     lable: '',
    //     action: () => { },
    // },
    {
        icon: '',
        lable: templateConfig.mobilePhoneView,
        action: () => { window.open(`tel:${templateConfig.mobilePhone}`) },
    },
    {
        icon: 'map-marker',
        lable: '  Tumen',
        action: () => { },
    },
])
</script>

<style lang="scss" scoped>
#appbar {
    height: 74px;
}

.company-title {
    text-transform: uppercase;
    font-size: 30px;
}
</style>