<template>
    <div class="grid">
        <div class="col-1 flex justify-content-center align-items-center">
            <AngleLeft class="navigation-angle" style="height: 30px;" @click="prev()" />
        </div>
        <div class="col-10">
            <Carousel ref="articleCarousel" v-bind:settings="carouselSettings" :breakpoints="breakpointsConfig" class="m-0">
                <Slide v-for="(article, index) in articles" :key="index">
                    <div class="carousel__item w-15rem">
                        <ArticlCard :imageUrl="article.imageUrl" :description="article.summary" :date="article.date" />
                    </div>
                </Slide>
            </Carousel>
        </div>
        <div class="col-1 flex justify-content-center align-items-center">
            <AngleRight class="navigation-angle" style="height: 30px;" @click="next()" />
        </div>
    </div>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { ref, defineProps } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import ArticlCard from '@/components/cards/ArticlCard.vue'
import AngleRight from '@/components/icons/AngleRight.vue'
import AngleLeft from '@/components/icons/AngleLeft.vue'

const breakpointsConfig = ref({
    576: {
        itemsToShow: 1.7,
        snapAlign: 'center',
    },
    768: {
        itemsToShow: 2.5,
        snapAlign: 'center',
    },
    992: {
        itemsToShow: 3,
        snapAlign: 'center',
    },
    1200: {
        itemsToShow: 4,
        snapAlign: 'center',
    },
})

const carouselSettings = ref({
    itemsToShow: 1,
    snapAlign: 'center',
    transition: 500,
})

const articleCarousel = ref()

const props = defineProps({
    articles: {
        type: Array,
    },
})

const prev = () => {
    articleCarousel.value.prev()
    articleCarousel.value.updateSlideWidth()
}

const next = () => {
    articleCarousel.value.next()
    articleCarousel.value.updateSlideWidth()
}
</script>

<style lang="scss">
.navigation-angle:hover {
    opacity: 0.5;
    cursor: pointer;
}

.carousel__slide {
    padding: 5px;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 1;
}

.carousel__slide--prev {
    opacity: 1;
}

.carousel__slide--next {
    opacity: 1;
}

.carousel__slide--active {
    opacity: 1;
}
</style>