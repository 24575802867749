<template>
    <Carousel v-bind:settings="carouselSettings" :breakpoints="breakpointsConfig" class="m-0">
        <Slide v-for="(partner, index) in partners" :key="index">
            <div class="carousel__item w-15rem">
                <PartnerCard :imageUrl="partner.imageUrl" :name="partner.name" />
            </div>
        </Slide>
    </Carousel>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel'
import PartnerCard from '@/components/cards/PartnerCard.vue';

const breakpointsConfig = ref({
    576: {
        itemsToShow: 1.7,
        snapAlign: 'center',
    },
    768: {
        itemsToShow: 2.5,
        snapAlign: 'center',
    },
    992: {
        itemsToShow: 3,
        snapAlign: 'center',
    },
    1200: {
        itemsToShow: 4,
        snapAlign: 'center',
    },
})

const carouselSettings = ref({
    itemsToShow: 1,
    snapAlign: 'center',
    transition: 500,
})

const partners = ref([
    {
        name: 'Почта России',
        imageUrl: 'images/partners/russian-post.svg',
    },
    {
        name: 'Тюменский бройлер',
        imageUrl: 'images/partners/tymen-broler.svg',
    },
    {
        name: 'OZON',
        imageUrl: 'images/partners/ozon.svg',
    },
    {
        name: 'Лента',
        imageUrl: 'images/partners/lenta.svg',
    },
    {
        name: 'ТюменьАгро',
        imageUrl: 'images/partners/tymen-agro.svg',
    },
    {
        name: 'Завод ГафропакТюмень',
        imageUrl: 'images/partners/pakage-factory.svg',
    },
])

</script>

<style lang="scss">
.carousel__slide {
    padding: 5px;
}

.carousel__slide--sliding {
    transition: 0.5s;
}

.carousel__slide {
    opacity: 1;
}

.carousel__slide--prev {
    opacity: 1;
}

.carousel__slide--next {
    opacity: 1;
}

.carousel__slide--active {
    opacity: 1;
}
</style>