import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: () => import('@/views/VacanciesView.vue')
  },
  {
    path: '/vacancy/:slug',
    name: 'Vacancy',
    component: () => import('@/views/VacancyView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404View.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 5,
      }
    }

    return {
      el: '#app',
      behavior: "smooth",
    }
  },
})

export default router
