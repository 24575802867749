<template>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 8px;
  background-color: rgba(248, 248, 255, 1);
}

#app {
  font-family: Hind, sans-serif;
}
</style>
