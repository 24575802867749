<template>
    <footer>
        <div class="grid my-6">
            <div class="col-10 sm:col-4 col-offset-1 sm:col-offset-2 base-info">
                <div>Получите консультацию по телефону горячей линии</div>
                <div>{{ templateConfig.mobilePhoneView }}</div>
            </div>
            <div class="col-10 sm:col-4 col-offset-1 sm:col-offset-0 flex flex-column text-left sm:text-right base-info">
                <div>ООО "БИЛЛИОН"</div>
                <div>ИНН/КПП 7204153897/720301001</div>
                <div>ОГРН 1107232013151</div>
                <div>ОКПО 66601095</div>
            </div>
        </div>
        <DeviderLine />
        <div class="grid mb-8">
            <div class="col-10 sm:col-2 col-offset-1 sm:col-offset-2 text-center sm:text-left footer-col">
                <h2>Время работы</h2>
                <div>8:00-22:00</div>
                <div>без перерывов и выходных</div>
            </div>
            <div class="col-10 sm:col-2 col-offset-1 sm:col-offset-0 text-center sm:text-left footer-col">
                <h2>Контакты</h2>
                <div>{{ templateConfig.email }}</div>
                <div>{{ templateConfig.mobilePhoneView }}</div>
                <div>{{ templateConfig.mobilePhone1View }}</div>
                <div>{{ templateConfig.mobileShortPhoneView }}</div>
                <div class="flex flex-row align-items-center">

                </div>
            </div>
            <div class="col-10 sm:col-2 col-offset-1 sm:col-offset-0 text-center sm:text-left footer-col">
                <h2>Меню</h2>
                <div v-for="(link, index) in links" :key="index" style="cursor: pointer"
                    @click="router.push({ name: link.routeName, hash: link.hash })">
                    {{ link.lable }}
                </div>
            </div>
            <div class="col-10 sm:col-2 col-offset-1 sm:col-offset-0 text-center sm:text-left footer-col">
                <h2>Адрес</h2>
                <div>г.Тюмень,</div>
                <div>ул, Московский тракт,</div>
                <div>д. 165/1, офис 303</div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import templateConfig from '@/store/templateConfig'
import DeviderLine from '@/components/deviders/DeviderLine.vue'

const router = useRouter()

const links = ref([
    {
        lable: 'Главная',
        routeName: 'Home',
        hash: '',
    },
    {
        lable: 'Вакансии',
        routeName: 'Vacancies',
        hash: '',
    },
    {
        lable: 'О нас',
        routeName: 'About',
        hash: '',
    },
    {
        lable: 'Личный кабинет',
        routeName: '',
        hash: '',
    },
])
</script>

<style lang="scss">
.base-info {
    font-size: 15px;
    font-weight: 400;
    color: rgb(140, 140, 140);
}

.footer-col h2 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: rgb(61, 79, 255);
    line-height: 30px;
}

.footer-col {
    font-size: 14px;
    font-weight: 400;
    color: rgb(140, 140, 140);
    line-height: 30px;
}
</style>