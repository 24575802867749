<template>
    <div class="grid">
        <div class="col-10 md:col-5 col-offset-1 mt-4 text-lg sm:text-2xl contact-info">
            <div>Оставьте заявку на получение услуги</div>
            <div style="color: rgb(110, 120, 253);">или</div>
            <div>позвоните по номеру <br> +7 (904) 495 10 27</div>
        </div>
        <div class="col-10 md:col-5 col-offset-1 md:col-offset-0">
            <SectionTitle class="my-4" :title="'Заполните анкету'" uppercase />
            <div>
                <div class="p-fluid mb-5">
                    <span class="p-float-label form-element">
                        <InputText v-model="formData.fullname" id="fullName" />
                        <label for="fullName">Имя и Фамилия</label>
                    </span>
                </div>
                <div class="flex flex-column md:flex-row gap-3 mb-5">
                    <div class="p-inputgroup flex-1 mb-3 md:mb-0">
                        <span class="p-float-label form-element">
                            <InputMask v-model="formData.phone" id="phone" mask="(999) 999-9999" />
                            <label for="phone">Телефон</label>
                        </span>
                    </div>

                    <div class="p-inputgroup flex-1">
                        <span class="p-float-label form-element">
                            <InputText v-model="formData.email" id="email" />
                            <label for="email">Email</label>
                        </span>
                    </div>
                </div>
                <div class="flex flex-column md:flex-row gap-3 mb-5">
                    <div class="p-inputgroup flex-1 mb-3 md:mb-0">
                        <span class="p-float-label form-element">
                            <InputText v-model="formData.service" id="service" />
                            <label for="service">Интересующая услуга</label>
                        </span>
                    </div>

                    <div class="p-inputgroup flex-1">
                        <Dropdown class="form-dropdown" v-model="formData.owner" :options="customer" optionLabel="name"
                            placeholder="Заказчик" />
                    </div>
                </div>
                <div class="p-fluid mb-5">
                    <span class="p-float-label form-element">
                        <InputText v-model="formData.comment" id="comment" />
                        <label for="comment">Комментарий</label>
                    </span>
                </div>
                <GradientButton @click="sendForm" class="w-full h-3rem border-round-3xl" :lable="'Отправить заявку'" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import Dropdown from 'primevue/dropdown'
import GradientButton from '@/components/GradientButton.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import formsReauests from '@/api/requests/forms.js'

const formData = ref({
    fullname: "",
    phone: "",
    email: "",
    service: "",
    owner: "",
    comment: ""
})

const customer = ref([
    {
        name: 'Физическое лицо',
    },
    {
        name: 'Юридическое лицо',
    },
])

const sendForm = () => {
    let owner = formData.value.owner
    formData.value.owner = owner.name

    formsReauests.sendServiceForm(formData.value)
}
</script>

<style lang="scss" scoped>
.form-element input {
    border-radius: 1rem !important;
}

.form-element label {
    padding-left: 1rem;
}

.form-dropdown {
    border-radius: 1rem !important;
    padding-left: 1rem;
}

.contact-info {
    font-weight: 400;
    text-transform: uppercase;
}
</style>